import { useState, useEffect } from 'react';

const useSearch = (key) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const targetUrl = 'https://www.storecard.com.br/search/?key=' + key + '';

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(targetUrl);
        const data = await response.json();
        setData(data);
        setError(null);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [key]);

  return { data, loading, error };
};

export default useSearch;
