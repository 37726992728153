import React from 'react';
import { Container, Box } from '@mui/material';

import HeaderStore from './HeaderStore';
import FooterStore from './FooterStore';
import '../global.css';

const Termos = () => {
  return (
    <Container maxWidth="false" disableGutters>
      <HeaderStore />
      <Box
        alignItems="center"
        textAlign="center"
        style={{ color: '#000', padding: '100px', backgroundColor: '#fff' }}
      >
        Site em construção inauguração provista para final de Maio de 2024
      </Box>
      <FooterStore />
    </Container>
  );
};

export default Termos;
