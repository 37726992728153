import useFetch from './useFetch';

const useMenus = () => {
  const { data, error, isLoading } = useFetch('./store/menus/');
  return { menus: data, menusError: error, menusLoading: isLoading };
};

const useCards = () => {
  const { data, error, isLoading } = useFetch('./store/cards/');
  return { cards: data, cardsError: error, cardsLoading: isLoading };
};

export { useMenus, useCards };
