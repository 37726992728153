import { useEffect } from 'react';
import useStore from './useStore';

const useFetch = (url, type) => {
  const { menus, cards, setMenus, setCards, setError } = useStore();
  const data = type === 'menus' ? menus : cards;
  const setData = type === 'menus' ? setMenus : setCards;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setData(data);
      } catch (error) {
        setError(error);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [url, data, setData, setError]);

  return { data };
};

export default useFetch;
